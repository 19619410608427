






















































































import { Component, Prop, PropSync, Provide, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {},
})
export default class NavigationDrawer extends Vue {
  @PropSync('show') drawer!: boolean
  @Provide() walletStore = walletStore

  addressWallet = ''
  email = ''

  async login() {
    const res = await this.walletStore.signIn()
    if (res) this.$emit('toggleDrawer')
  }
}
